<template>
  <section>
    <v-row class="mb-5 text-center my-5">
      <v-col class="d-flex" cols="12" md="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
              <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
                >Les rendez-vous en attente d'affectation</v-card-subtitle
              >
              <loading
                transition="fade"
                :active.sync="waitTimeoutAppointments"
                :can-cancel="false"
                :is-full-page="false"
                loader="spinner"
                color="#efb639"
                :opacity="1"
              >
              </loading>
              <v-card-text v-if="appointments.length > 0" class="text-center pa-5 mt-n5">
                <v-badge :content="appointments.length" color="EoleError" bottom overlap>
                  <i class="fa-solid fa-briefcase fa-4x EoleBlue--text"></i>
                </v-badge>
                <p class="mt-5">Il y a {{ appointments.length }} rendez-vous en attente d'affectation.</p>
              </v-card-text>
              <v-card-text v-else class="text-center pa-5 mt-n5">
                <i class="fa-solid fa-briefcase fa-4x EoleBlue--text"></i>
                <p class="mt-5">Aucun rendez-vous programmés</p>
              </v-card-text>
            </v-card>
          </template>
          <span>Les rendez-vous en attente sont affichés dans la liste ci-dessous. </span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
              <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
                >Mes rendez-vous programmés</v-card-subtitle
              >
              <loading
                transition="fade"
                :active.sync="waitTimeoutAppointmentsAttributed"
                :can-cancel="false"
                :is-full-page="false"
                loader="spinner"
                color="#efb639"
                :opacity="1"
              >
              </loading>
              <v-card-text v-if="appointmentsAttributed.length > 0" class="text-center pa-5 mt-n5">
                <v-badge :content="appointmentsAttributed.length" color="EoleError" bottom overlap>
                  <i class="fa-solid fa-briefcase fa-4x EoleBlue--text"></i>
                </v-badge>
                <p class="mt-5">Il y a {{ appointmentsAttributed.length }} rendez-vous en attente d'affectation.</p>
              </v-card-text>
              <v-card-text v-else class="text-center pa-5 mt-n5">
                <i class="fa-solid fa-clock-rotate-left fa-4x EoleBlue--text"></i>
                <p class="mt-5">Aucun rendez-vous programmés</p>
              </v-card-text>
            </v-card>
          </template>
          <span>Les rendez-vous en attente sont affichés dans la liste ci-dessous. </span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column" link v-bind="attrs" v-on="on" outlined>
              <v-card-title>Rendez-vous en retard</v-card-title>
              <loading
                transition="fade"
                :active.sync="waitTimeoutAppointmentsAttributedTreated"
                :can-cancel="false"
                :is-full-page="false"
                loader="spinner"
                color="#efb639"
                :opacity="1"
              >
              </loading>
              <div>
                <v-card-text>
                  <i class="fa-solid fa-warning fa-3x EoleError--text"></i>
                </v-card-text>
                <v-card-text>
                  <h2>{{ contactLate }} (vous)</h2>
                </v-card-text>
                <v-card-text>
                  <h2>{{ contactLateAll }} (Agence)</h2>
                </v-card-text>
              </div>
            </v-card>
          </template>
          <span>Les appels traités sont affichés dans la liste ci-dessous. </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-tabs
      v-model="tabAppointment"
      style="box-shadow: none !important"
      background-color="transparent"
      color="EoleYellow"
    >
      <v-tab href="#tab-1"> Tous les rendez-vous </v-tab>
      <v-tab href="#tab-2">Mes rendez-vous</v-tab>
      <v-tab href="#tab-3">Mes rendez-vous passés</v-tab>
      <v-tab href="#tab-4" v-if="isAdmin">Vue sur les collaborateurs</v-tab>
      <v-tab href="#tab-5">Calendrier</v-tab>
    </v-tabs>
    <v-divider class="mb-5"></v-divider>
    <v-tabs-items v-model="tabAppointment" style="background-color: transparent !important">
      <v-tab-item :value="'tab-1'" class="mb-5">
        <loading
          transition="fade"
          :active.sync="waitTimeoutAppointments"
          :can-cancel="false"
          :is-full-page="false"
          loader="spinner"
          color="#efb639"
          :opacity="1"
        >
        </loading>
        <v-row v-if="appointments.length > 0">
          <v-col class="d-flex" cols="12" md="3" v-for="(appointment, index) in appointments" :key="index">
            <v-card outlined elevation="3" class="flex d-flex flex-column rounded-xl">
              <v-row class="pa-5">
                <v-col class="text-left" cols="12" md="9" v-if="appointment.anteriatory">
                  <i class="fa-solid fa-warning EoleError--text"></i>
                  Retard constaté
                </v-col>
                <v-col class="text-left" cols="12" md="9" v-else> </v-col>
                <v-col class="text-right" cols="12" md="3">
                  <v-menu right transition="slide-x-transition" close-on-content-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-subheader>Mes actions disponibles</v-subheader>

                      <v-list-item link color="EoleBlue" :disabled="transferInProgress">
                        <v-dialog
                          transition="slide-x-transition"
                          max-width="1200"
                          v-model="dialogTransferContact"
                          :persistent="transferInProgress"
                          close-on-content-click
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">Affecter le rendez-vous </v-list-item-title>
                            <v-list-item-action>
                              <i class="fa-solid fa-share-nodes ml-2"></i>
                            </v-list-item-action>
                          </template>
                          <template v-slot:default="dialogContact">
                            <v-card
                              class="rounded-l-xl"
                              style="box-shadow: 0 0 60px -20px #000000d9"
                              min-height="800px"
                              max-height="800px"
                            >
                              <v-card-text style="background-color: #f1f4f9">
                                <v-row>
                                  <v-col cols="12" md="3" class="no-padding ml-n2">
                                    <v-card
                                      min-height="800px"
                                      max-height="800px"
                                      class="rounded-l-xl"
                                      color="#f1f4f9"
                                      style="background-color: #f1f4f9"
                                      flat
                                    >
                                      <v-card-title>
                                        <v-img
                                          :src="require('@/assets/images/logos/anavel_logo.png')"
                                          max-height="150px"
                                          max-width="120px"
                                          alt="logo"
                                          contain
                                        ></v-img>
                                      </v-card-title>
                                      <v-card-title class="text-center justify-center muller-capitalized"
                                        >Mes Services Assurance</v-card-title
                                      >
                                      <v-card-subtitle class="text-center justify-center"
                                        >Un logiciel ANAVEL</v-card-subtitle
                                      >
                                      <v-card-text class="no-padding">
                                        <v-img
                                          :src="require('@/assets/images/logos/layer.png')"
                                          alt="logo"
                                          width="300px"
                                          class=""
                                        >
                                        </v-img>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="12" md="9" class="no-padding d-flex">
                                    <v-card
                                      class="rounded-r-xl flex-fill flex d-flex flex-column"
                                      flat
                                      :loading="transferInProgress"
                                    >
                                      <v-card-title class="text-h5">
                                        <v-row>
                                          <v-col cols="12" md="10" class="text-left">Affectation du rendez-vous</v-col>
                                        </v-row>
                                      </v-card-title>
                                      <v-card-subtitle
                                        >Sélectionnez un collaborateur dans la liste pour changer l'assignation de cette
                                        demande
                                      </v-card-subtitle>
                                      <v-card-text class="mb-n5">
                                        <v-row>
                                          <v-col cols="12" md="12">
                                            <v-text-field
                                              dense
                                              prepend-inner-icon="mdi-account-search"
                                              outlined
                                              label="Rechercher un collaborateur"
                                              v-model="searchCollaborator"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                      <v-card-text> </v-card-text>
                                      <v-card-text class="no-padding">
                                        <v-list dense :disabled="transferInProgress">
                                          <v-list-item
                                            v-for="(collaborator, index) in collaboratorFiltering(
                                              collaboratorsAvailable,
                                            )"
                                            :key="index"
                                            :disabled="transferInProgress"
                                            color="EoleBlue"
                                            link
                                            @click="transferAppointment(appointment, collaborator.id)"
                                          >
                                            <v-list-item-avatar
                                              v-if="collaborator.avatarUrl != null"
                                              class="elevation-10"
                                            >
                                              <v-img :src="collaborator.avatarUrl"></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-avatar v-else color="EoleBlue" class="text-center">
                                              <v-avatar color="red">
                                                <span class="white--text mr-2">{{
                                                  collaborator.firstname[0] + collaborator.lastname[0]
                                                }}</span>
                                              </v-avatar>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                              <v-list-item-title>{{ collaborator.completName }}</v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </v-list>
                                      </v-card-text>
                                      <v-spacer></v-spacer>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </template>
                        </v-dialog>
                      </v-list-item>
                      <!-- <v-list-item link>
                        <v-menu offset-x>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">
                              <v-icon left>mdi-send</v-icon>Affecter le rendez-vous
                            </v-list-item-title>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(collaborator, index) in collaboratorsAvailable"
                              :key="index"
                              link
                              @click="transferAppointment(appointment, collaborator.id, collaborator.completName)"
                            >
                              <v-list-item-title>{{ collaborator.completName }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item> -->
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-card-text class="text-center">
                <i
                  v-if="appointment.appointment_type === 'En agence'"
                  class="fa-solid fa-calendar-days fa-3x EoleBlue--text text-center"
                ></i>
                <i v-else class="fa-solid fa-phone fa-3x EoleBlue--text text-center"></i>
              </v-card-text>
              <v-card-text class="text-center"> Une demande effectuée par : </v-card-text>
              <v-card-text class="text-center EoleBlue--text mt-n4">
                <p class="text-h6">{{ appointment.civility }} {{ appointment.lastname }} {{ appointment.firstname }}</p>
              </v-card-text>
              <v-row v-if="appointment.appointment == 1">
                <v-card-text class="text-center mt-n5"> Demande de rendez-vous pour le : </v-card-text>
                <v-card-text class="text-center EoleYellow--text mt-n4">
                  <p class="text-h6">{{ appointment.date }}</p>
                  <p class="text-h6 mt-n6">{{ appointment.hourStart }} - {{ appointment.hourEnd }}</p>
                </v-card-text>
              </v-row>
              <v-row v-else>
                <v-card-text class="text-center mt-n5"> Délais de 48h : {{ appointment.maxDelay }}</v-card-text>
              </v-row>

              <v-row v-if="appointment.appointment_type === 'En agence'">
                <v-col cols="12" md="6">
                  <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                  <v-card-text class="text-center EoleBlue--text mt-n4">
                    <p class="text-h6">{{ appointment.appointment_type }}</p>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card-text class="text-center mt-n5"> A l'agence de : </v-card-text>
                  <v-card-text class="text-center EoleBlue--text mt-n4">
                    <p class="text-h6">{{ appointment.agency }}</p>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-else-if="appointment.appointment == 1">
                <v-col cols="12" md="6">
                  <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                  <v-card-text class="text-center EoleYellow--text mt-n4">
                    <p class="text-h6">{{ appointment.appointment_type }}</p>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="6" class="mt-3">
                  <v-card-text class="text-center mt-n5"> </v-card-text>
                  <v-card-text class="text-center EoleYellow--text mt-n4">
                    <v-btn color="EoleBlue" class="white--text" :href="`tel:+${appointment.phone_number}`"
                      >Appeler</v-btn
                    >
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" md="6" align-self="center">
                  <v-card-text class="text-center mt-n5"> Numéro de rappel : </v-card-text>
                  <v-card-text class="text-center EoleYellow--text mt-n4">
                    <p class="text-h6">{{ appointment.formated_phone_number }}</p>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="6" class="mt-3">
                  <v-card-text class="text-center mt-n5"> </v-card-text>
                  <v-card-text class="text-center EoleYellow--text mt-n4">
                    <v-btn color="EoleBlue" class="white--text" :href="`tel:+${appointment.phone_number}`"
                      >Appeler</v-btn
                    >
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5 pb-5 mt-n5 justify-center">
                <v-col cols="12" md="4" v-for="contract in appointment.contracts" v-bind:key="contract.name">
                  <v-card
                    link
                    @mouseover="contract.hovered = true"
                    @mouseleave="contract.hovered = false"
                    outlined
                    width="125"
                    height="100"
                    elevation="3"
                    class="align-center d-flex rounded-xl"
                  >
                    <v-card-text class="text-center pa-5">
                      <i :class="['mt-4 fa-solid ', `fa-${contract.icon}`, 'fa-2x', 'EoleBlue--text']"></i>
                      <p class="mt-2 text-truncate" style="font-size: 0.6rem">{{ contract.name }}</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else class="text-center justify-center">
          <div class="mt-15">
            <v-card-text>
              <i class="fa-solid fa-calendar-xmark fa-6x EoleBlue--text"></i>
            </v-card-text>
            <v-card-text>
              Il n'y a pas pour le moment aucun rendez-vous de programmé. <br />
              Vous serez notifié par email dès qu'un rendez-vous vous sera programmé par un client.
              <br />
              N'hésitez pas à aller vous chercher un café et à repasser dans quelques instants. 😉
            </v-card-text>
          </div>
        </v-row>
      </v-tab-item>

      <v-tab-item :value="'tab-2'" class="mb-5">
        <loading
          transition="fade"
          :active.sync="waitTimeoutAppointmentsAttributed"
          :can-cancel="false"
          :is-full-page="false"
          loader="spinner"
          color="#efb639"
          :opacity="1"
        >
        </loading>
        <v-row v-if="appointmentsAttributed.length > 0">
          <v-col class="d-flex" cols="12" md="3" v-for="appointment in appointmentsAttributed" :key="appointment.id">
            <v-card outlined elevation="3" class="flex d-flex flex-column rounded-xl">
              <v-row class="pa-5">
                <v-col class="text-left" cols="12" md="9" v-if="appointment.anteriatory">
                  <i class="fa-solid fa-warning EoleError--text"></i>
                  Retard constaté
                </v-col>
                <v-col class="text-left" cols="12" md="9" v-else> </v-col>
                <v-col class="text-right" cols="12" md="3">
                  <v-menu right transition="slide-x-transition" close-on-content-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <!-- <v-list>
                      <v-list-item link>
                        <v-menu offset-x>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">
                              <v-icon left>mdi-send</v-icon>Affecter le rendez-vous
                            </v-list-item-title>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(collaborator, index) in collaboratorsAvailable"
                              :key="index"
                              link
                              @click="transferAppointment(appointment, collaborator.id, collaborator.completName)"
                            >
                              <v-list-item-title>{{ collaborator.completName }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item>
                      <v-list-item color="EoleError" link @click="archiveAppointment(appointment.id)">
                        <v-list-item-title>
                          <v-icon color="EoleGreen" left>mdi-check</v-icon>Rendez-vous traité
                        </v-list-item-title>
                      </v-list-item>
                    </v-list> -->

                    <v-list dense>
                      <v-subheader>Mes actions disponibles</v-subheader>

                      <v-list-item link color="EoleBlue" :disabled="transferInProgress">
                        <v-dialog
                          transition="slide-x-transition"
                          max-width="1200"
                          v-model="dialogTransferContact"
                          :persistent="transferInProgress"
                          close-on-content-click
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">Affecter le rendez-vous </v-list-item-title>
                            <v-list-item-action>
                              <i class="fa-solid fa-share-nodes ml-2"></i>
                            </v-list-item-action>
                          </template>
                          <template v-slot:default="dialogContact">
                            <v-card
                              class="rounded-l-xl"
                              style="box-shadow: 0 0 60px -20px #000000d9"
                              min-height="800px"
                              max-height="800px"
                            >
                              <v-card-text style="background-color: #f1f4f9">
                                <v-row>
                                  <v-col cols="12" md="3" class="no-padding ml-n2">
                                    <v-card
                                      min-height="800px"
                                      max-height="800px"
                                      class="rounded-l-xl"
                                      color="#f1f4f9"
                                      style="background-color: #f1f4f9"
                                      flat
                                    >
                                      <v-card-title>
                                        <v-img
                                          :src="require('@/assets/images/logos/anavel_logo.png')"
                                          max-height="150px"
                                          max-width="120px"
                                          alt="logo"
                                          contain
                                        ></v-img>
                                      </v-card-title>
                                      <v-card-title class="text-center justify-center muller-capitalized"
                                        >Mes Services Assurance</v-card-title
                                      >
                                      <v-card-subtitle class="text-center justify-center"
                                        >Un logiciel ANAVEL</v-card-subtitle
                                      >
                                      <v-card-text class="no-padding">
                                        <v-img
                                          :src="require('@/assets/images/logos/layer.png')"
                                          alt="logo"
                                          width="300px"
                                          class=""
                                        >
                                        </v-img>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="12" md="9" class="no-padding d-flex">
                                    <v-card
                                      class="rounded-r-xl flex-fill flex d-flex flex-column"
                                      flat
                                      :loading="transferInProgress"
                                    >
                                      <v-card-title class="text-h5">
                                        <v-row>
                                          <v-col cols="12" md="10" class="text-left">Affectation du rendez-vous</v-col>
                                        </v-row>
                                      </v-card-title>
                                      <v-card-subtitle
                                        >Sélectionnez un collaborateur dans la liste pour changer l'assignation de cette
                                        demande
                                      </v-card-subtitle>
                                      <v-card-text class="mb-n5">
                                        <v-row>
                                          <v-col cols="12" md="12">
                                            <v-text-field
                                              dense
                                              prepend-inner-icon="mdi-account-search"
                                              outlined
                                              label="Rechercher un collaborateur"
                                              v-model="searchCollaborator"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                      <v-card-text> </v-card-text>
                                      <v-card-text class="no-padding">
                                        <v-list dense :disabled="transferInProgress">
                                          <v-list-item
                                            v-for="(collaborator, index) in collaboratorFiltering(
                                              collaboratorsAvailable,
                                            )"
                                            :key="index"
                                            :disabled="transferInProgress"
                                            color="EoleBlue"
                                            link
                                            @click="transferAppointment(appointment, collaborator.id)"
                                          >
                                            <v-list-item-avatar
                                              v-if="collaborator.avatarUrl != null"
                                              class="elevation-10"
                                            >
                                              <v-img :src="collaborator.avatarUrl"></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-avatar v-else color="EoleBlue" class="text-center">
                                              <v-avatar color="red">
                                                <span class="white--text mr-2">{{
                                                  collaborator.firstname[0] + collaborator.lastname[0]
                                                }}</span>
                                              </v-avatar>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                              <v-list-item-title>{{ collaborator.completName }}</v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </v-list>
                                      </v-card-text>
                                      <v-spacer></v-spacer>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </template>
                        </v-dialog>
                      </v-list-item>
                      <v-divider class="my-2"></v-divider>

                      <v-list-item color="EoleError" link @click="archiveAppointment(appointment.id)">
                        <v-list-item-title> Demande traitée</v-list-item-title>
                        <v-list-item-action>
                          <i class="fa-solid fa-circle-check ml-2 EoleGreen--text"></i>
                        </v-list-item-action>
                      </v-list-item>
                      <!-- <v-list-item link>
                        <v-menu offset-x>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">
                              <v-icon left>mdi-send</v-icon>Affecter le rendez-vous
                            </v-list-item-title>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(collaborator, index) in collaboratorsAvailable"
                              :key="index"
                              link
                              @click="transferAppointment(appointment, collaborator.id, collaborator.completName)"
                            >
                              <v-list-item-title>{{ collaborator.completName }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item> -->
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-card-text class="text-center">
                <i
                  v-if="appointment.appointment_type === 'En agence'"
                  class="fa-solid fa-calendar-days fa-3x EoleBlue--text text-center"
                ></i>
                <i v-else class="fa-solid fa-phone fa-3x EoleBlue--text text-center"></i>
              </v-card-text>
              <v-card-text class="text-center"> Une demande effectuée par : </v-card-text>
              <v-card-text class="text-center EoleBlue--text mt-n4">
                <p class="text-h6">{{ appointment.civility }} {{ appointment.lastname }} {{ appointment.firstname }}</p>
              </v-card-text>
              <v-row v-if="appointment.appointment == 1">
                <v-card-text class="text-center mt-n5"> Demande de rendez-vous pour le : </v-card-text>
                <v-card-text class="text-center EoleYellow--text mt-n4">
                  <p class="text-h6">{{ appointment.date }}</p>
                  <p class="text-h6 mt-n6">{{ appointment.hourStart }} - {{ appointment.hourEnd }}</p>
                </v-card-text>
              </v-row>
              <v-row v-else>
                <v-card-text class="text-center mt-n5"> Délais de 48h : {{ appointment.maxDelay }}</v-card-text>
              </v-row>
              <v-row v-if="appointment.appointment_type === 'En agence'">
                <v-col cols="12" md="6">
                  <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                  <v-card-text class="text-center EoleBlue--text mt-n4">
                    <p class="text-h6">{{ appointment.appointment_type }}</p>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card-text class="text-center mt-n5"> A l'agence de : </v-card-text>
                  <v-card-text class="text-center EoleBlue--text mt-n4">
                    <p class="text-h6">{{ appointment.agency }}</p>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-else-if="appointment.appointment == 1">
                <v-col cols="12" md="6">
                  <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                  <v-card-text class="text-center EoleYellow--text mt-n4">
                    <p class="text-h6">{{ appointment.appointment_type }}</p>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="6" class="mt-3">
                  <v-card-text class="text-center mt-n5"> </v-card-text>
                  <v-card-text class="text-center EoleYellow--text mt-n4">
                    <v-btn color="EoleBlue" class="white--text" :href="`tel:+${appointment.phone_number}`"
                      >Appeler</v-btn
                    >
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" md="6" align-self="center">
                  <v-card-text class="text-center mt-n5"> Numéro de rappel : </v-card-text>
                  <v-card-text class="text-center EoleYellow--text mt-n4">
                    <p class="text-h6">{{ appointment.formated_phone_number }}</p>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="6" class="mt-3">
                  <v-card-text class="text-center mt-n5"> </v-card-text>
                  <v-card-text class="text-center EoleYellow--text mt-n4">
                    <v-btn color="EoleBlue" class="white--text" :href="`tel:+${appointment.phone_number}`"
                      >Appeler</v-btn
                    >
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5 pb-5 mt-n5 justify-center">
                <v-col cols="12" md="4" v-for="contract in appointment.contracts" v-bind:key="contract.name">
                  <v-card
                    link
                    @mouseover="contract.hovered = true"
                    @mouseleave="contract.hovered = false"
                    outlined
                    width="125"
                    height="100"
                    elevation="3"
                    class="align-center d-flex rounded-xl"
                  >
                    <v-card-text class="text-center pa-5">
                      <i
                        :class="[
                          'mt-4 fa-solid ',
                          `fa-${contract.icon}`,
                          'fa-2x',
                          contract.hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                        ]"
                      ></i>
                      <p class="mt-2" style="font-size: 0.6rem">{{ contract.name }}</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else class="text-center justify-center">
          <div class="mt-15">
            <v-card-text>
              <i class="fa-solid fa-calendar-check fa-6x EoleBlue--text"></i>
            </v-card-text>
            <v-card-text>
              Vous n'avez pour le moment pas de rendez-vous programmé.<br />
              Vous souhaitez en avoir ? <br />
              Allez à la rubrique <a @click="tabAppointment = 'tab-1'">tous les rendez-vous</a> et affectez-vous un
              rendez-vous. <br />
            </v-card-text>
          </div>
        </v-row>
      </v-tab-item>

      <v-tab-item :value="'tab-3'" class="mb-5">
        <loading
          transition="fade"
          :active.sync="waitTimeoutAppointments"
          :can-cancel="false"
          :is-full-page="false"
          loader="spinner"
          color="#efb639"
          :opacity="1"
        >
        </loading>
        <v-row v-if="appointmentsTreated.length > 0">
          <v-col class="d-flex" cols="12" md="3" v-for="appointment in appointmentsTreated" :key="appointment.id">
            <v-card disabled outlined elevation="3" class="flex d-flex flex-column rounded-xl">
              <v-row class="pa-5">
                <v-col class="text-left" cols="12" md="9"></v-col>
                <v-col class="text-right" cols="12" md="3">
                  <v-menu disabled left right transition="slide-x-transition" close-on-content-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item link>
                        <v-menu offset-x>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">
                              <v-icon left>mdi-send</v-icon>Affecter le rendez-vous
                            </v-list-item-title>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(collaborator, index) in collaboratorsAvailable"
                              :key="index"
                              link
                              @click="transferAppointment(appointment, collaborator.id, collaborator.completName)"
                            >
                              <v-list-item-title>{{ collaborator.completName }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item>
                      <v-list-item color="EoleError" link @click="archiveAppointment(appointment.id)">
                        <v-list-item-title>
                          <v-icon color="EoleGreen" left>mdi-check</v-icon>Rendez-vous traité
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-card-text class="text-center">
                <i
                  v-if="appointment.appointment_type === 'En agence'"
                  class="fa-solid fa-calendar-days fa-3x EoleBlue--text text-center"
                ></i>
                <i v-else class="fa-solid fa-phone fa-3x EoleBlue--text text-center"></i>
              </v-card-text>
              <v-card-text class="text-center"> Une demande effectuée par : </v-card-text>
              <v-card-text class="text-center EoleYellow--text mt-n4">
                <p class="text-h6">{{ appointment.civility }} {{ appointment.lastname }} {{ appointment.firstname }}</p>
              </v-card-text>
              <v-row v-if="appointment.appointment == 1">
                <v-card-text class="text-center mt-n5"> Demande de rendez-vous pour le : </v-card-text>
                <v-card-text class="text-center EoleYellow--text mt-n4">
                  <p class="text-h6">{{ appointment.date }}</p>
                  <p class="text-h6 mt-n6">{{ appointment.hourStart }} - {{ appointment.hourEnd }}</p>
                </v-card-text>
              </v-row>
              <v-row v-else>
                <v-card-text class="text-center mt-n5"> Délais de 48h : {{ appointment.maxDelay }}</v-card-text>
              </v-row>
              <v-row v-if="appointment.appointment_type === 'En agence'">
                <v-col cols="12" md="6">
                  <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                  <v-card-text class="text-center EoleBlue--text mt-n4">
                    <p class="text-h6">{{ appointment.appointment_type }}</p>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card-text class="text-center mt-n5"> A l'agence de : </v-card-text>
                  <v-card-text class="text-center EoleBlue--text mt-n4">
                    <p class="text-h6">{{ appointment.agency }}</p>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-else-if="appointment.appointment == 1">
                <v-col cols="12" md="6">
                  <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                  <v-card-text class="text-center EoleYellow--text mt-n4">
                    <p class="text-h6">{{ appointment.appointment_type }}</p>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="6" class="mt-3">
                  <v-card-text class="text-center mt-n5"> </v-card-text>
                  <v-card-text class="text-center EoleYellow--text mt-n4">
                    <v-btn color="EoleBlue" class="white--text" :href="`tel:+${appointment.phone_number}`"
                      >Appeler</v-btn
                    >
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" md="6" align-self="center">
                  <v-card-text class="text-center mt-n3"> Souhaite être rappelé</v-card-text>
                </v-col>
                <v-col cols="12" md="6" class="mt-3">
                  <v-card-text class="text-center mt-n5"> </v-card-text>
                  <v-card-text class="text-center EoleYellow--text mt-n4">
                    <v-btn color="EoleBlue" class="white--text" :href="`tel:+${appointment.phone_number}`"
                      >Appeler</v-btn
                    >
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5 pb-5 mt-n5 justify-center">
                <v-col cols="12" md="4" v-for="contract in appointment.contracts" v-bind:key="contract.name">
                  <v-card
                    link
                    @mouseover="contract.hovered = true"
                    @mouseleave="contract.hovered = false"
                    outlined
                    width="125"
                    height="100"
                    elevation="3"
                    class="align-center d-flex rounded-xl"
                  >
                    <v-card-text class="text-center pa-5">
                      <i
                        :class="[
                          'mt-4 fa-solid ',
                          `fa-${contract.icon}`,
                          'fa-2x',
                          contract.hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                        ]"
                      ></i>
                      <p class="mt-2 text-truncate" style="font-size: 0.6rem">{{ contract.name }}</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else class="text-center justify-center">
          <div class="mt-15">
            <v-card-text>
              <i class="fa-solid fa-clock-rotate-leftfa-6x EoleBlue--text"></i>
            </v-card-text>
            <v-card-text> Vous n'avez pour le moment pas de rendez-vous historisé. </v-card-text>
          </div>
        </v-row>
      </v-tab-item>

      <v-tab-item :value="'tab-4'" class="mb-5">
        <loading
          transition="fade"
          :active.sync="waitTimeout"
          :can-cancel="false"
          :is-full-page="false"
          loader="dots"
          color="#efb639"
          :opacity="1"
        >
        </loading>

        <v-row class="my-5">
          <v-col cols="12" md="4">
            <v-card outlined>
              <v-card-title class="EoleBlueLighten">Liste des collaborateurs</v-card-title>
              <v-card-text class="pa-5 no-padding">
                <v-treeview
                  open-all
                  activatable
                  :active.sync="active"
                  :items="items"
                  :open.sync="open"
                  color="EoleBlue"
                  transition
                  selected-color="EoleYellow"
                >
                  <template v-slot:prepend="{ item }">
                    <v-icon v-if="item.children">mdi-phone</v-icon>
                    <v-spacer></v-spacer>
                    <v-badge
                      color="EoleError"
                      :content="item.contactNumber"
                      value="5"
                      class="my-2 mr-2"
                      v-if="item.id && item.contactNumber > 0"
                      bordered
                      bottom
                      offset-x="15"
                      offset-y="15"
                    >
                      <v-icon size="36">mdi-account</v-icon>
                    </v-badge>
                    <v-icon v-else-if="item.id && !item.children" size="36">mdi-account</v-icon>
                    {{ item.completName }}
                  </template>
                </v-treeview>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-center no-padding align-center mt-4 mr-2 EoleBlue--text">
                <p class="text-caption">{{ contactTotalCount }} appels en attente</p>
                <br />
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="8">
            <v-scroll-y-transition mode="out-in">
              <v-row v-if="!selected" align="center" class="text-left">
                <v-col>
                  <v-alert type="info" color="EoleBlue" prominent icon="mdi-account" text dense>
                    Veuillez selectionner un partenaire à afficher dans cette fenêtre.</v-alert
                  >
                </v-col>
              </v-row>
              <v-card v-else :key="selected.id" class="mx-auto" flat>
                <loading
                  transition="fade"
                  :active.sync="waitTimeoutContact"
                  :can-cancel="false"
                  :is-full-page="false"
                  loader="spinner"
                  color="#efb639"
                  :opacity="1"
                >
                </loading>
                <v-card outlined class="text-center">
                  <v-card-title class="EoleBlueLighten mb-5">
                    <v-row>
                      <v-col class="text-left" cols="12" md="9">Demandes pour {{ selected.completName }}</v-col>
                    </v-row>
                    <v-tabs
                      v-show="!waitTimeoutContact"
                      class="mt-5"
                      v-model="tab"
                      icons-and-text
                      style="box-shadow: none !important"
                      background-color="EoleBlueLighten"
                    >
                      <v-tab href="#tab-1">
                        Demandes à traiter
                        <v-icon>mdi-calendar</v-icon>
                      </v-tab>

                      <v-tab href="#tab-2">
                        Historique
                        <v-icon>mdi-calendar-refresh</v-icon>
                      </v-tab>
                    </v-tabs>
                  </v-card-title>

                  <v-tabs-items v-model="tab">
                    <v-tab-item :value="'tab-1'">
                      <v-row v-if="selected.contactNumber === 0" class="text-left pa-5 justify-center">
                        <v-alert type="error" prominent dense text
                          >Il n'y a aucune demande de rappel pour {{ selected.completName }}</v-alert
                        >
                      </v-row>
                      <v-row class="pa-5">
                        <v-col
                          class="d-flex"
                          cols="12"
                          md="6"
                          v-for="appointment in contact"
                          :key="appointment.idContact"
                        >
                          <v-card outlined elevation="3" class="flex d-flex flex-column rounded-xl">
                            <v-row class="pa-5">
                              <v-col class="text-left" cols="12" md="9"></v-col>
                              <v-col class="text-right" cols="12" md="3">
                                <v-menu left right transition="slide-x-transition" close-on-content-click>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list>
                                    <v-list-item link>
                                      <v-menu offset-x>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-list-item-title v-bind="attrs" v-on="on">
                                            <v-icon left>mdi-send</v-icon>Affecter le rendez-vous
                                          </v-list-item-title>
                                        </template>
                                        <v-list>
                                          <v-list-item
                                            v-for="(collaborator, index) in collaboratorsAvailable"
                                            :key="index"
                                            link
                                            @click="
                                              transferAppointmentTest(
                                                appointment,
                                                collaborator.id,
                                                collaborator.completName,
                                                selected.id,
                                              )
                                            "
                                          >
                                            <v-list-item-title>{{ collaborator.completName }} </v-list-item-title>
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-card-text class="text-center">
                              <i
                                v-if="appointment.appointment_type === 'En agence'"
                                class="fa-solid fa-calendar-days fa-3x EoleBlue--text text-center"
                              ></i>
                              <i v-else class="fa-solid fa-phone fa-3x EoleBlue--text text-center"></i>
                            </v-card-text>
                            <v-card-text class="text-center"> Une demande effectuée par : </v-card-text>
                            <v-card-text class="text-center EoleBlue--text mt-n4">
                              <p class="text-h6">
                                {{ appointment.civility }} {{ appointment.lastname }} {{ appointment.firstname }}
                              </p>
                            </v-card-text>
                            <v-row v-if="appointment.date_start != null">
                              <v-card-text class="text-center mt-n5"> Demande de rendez-vous pour le : </v-card-text>
                              <v-card-text class="text-center EoleYellow--text mt-n4">
                                <p class="text-h6">{{ appointment.date }}</p>
                                <p class="text-h6 mt-n6">{{ appointment.hourStart }} - {{ appointment.hourEnd }}</p>
                              </v-card-text>
                            </v-row>
                            <v-row v-if="appointment.appointment_type === 'En agence'">
                              <v-col cols="12" md="6">
                                <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                                <v-card-text class="text-center EoleYellow--text mt-n4">
                                  <p class="text-h6">{{ appointment.appointment_type }}</p>
                                </v-card-text>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-card-text class="text-center mt-n5"> A l'agence de : </v-card-text>
                                <v-card-text class="text-center EoleYellow--text mt-n4">
                                  <p class="text-h6">{{ appointment.agency }}</p>
                                </v-card-text>
                              </v-col>
                            </v-row>
                            <v-row v-else>
                              <v-col cols="12" md="6">
                                <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                                <v-card-text class="text-center EoleYellow--text mt-n4">
                                  <p class="text-h6">{{ appointment.appointment_type }}</p>
                                </v-card-text>
                              </v-col>
                              <v-col cols="12" md="6" class="mt-3">
                                <v-card-text class="text-center mt-n5"> </v-card-text>
                                <v-card-text class="text-center EoleYellow--text mt-n4">
                                  <v-btn color="EoleBlue" class="white--text" :href="`tel:+${appointment.phone_number}`"
                                    >Appeler</v-btn
                                  >
                                </v-card-text>
                              </v-col>
                            </v-row>
                            <v-row class="pl-5 pr-5 pb-5 mt-n5 justify-center">
                              <v-col
                                cols="12"
                                md="4"
                                v-for="contract in appointment.contracts"
                                v-bind:key="contract.name"
                              >
                                <v-card
                                  link
                                  @mouseover="contract.hovered = true"
                                  @mouseleave="contract.hovered = false"
                                  outlined
                                  width="125"
                                  height="100"
                                  elevation="3"
                                  class="align-center d-flex rounded-xl"
                                >
                                  <v-card-text class="text-center pa-5">
                                    <i
                                      :class="[
                                        'mt-4 fa-solid ',
                                        `fa-${contract.icon}`,
                                        'fa-2x',
                                        contract.hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                                      ]"
                                    ></i>

                                    <p class="mt-2" style="font-size: 0.6rem">{{ contract.name }}</p>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item :value="'tab-2'">
                      <v-row v-if="appointmentsTreated.length === 0" class="text-left pa-5 justify-center">
                        <v-alert type="error" prominent dense text>Il n'y a aucun historique à ce jours.</v-alert>
                      </v-row>
                      <v-row class="pa-5">
                        <v-row v-if="appointmentsTreated.length > 0">
                          <v-col cols="12">
                            <v-data-table
                              item-key="idContact"
                              class="elevation-1"
                              :loading="loading"
                              loading-text="Chargement des produits en cours... Merci de patienter"
                              :page.sync="page"
                              :items-per-page="10"
                              :headers="headersAppointmentsTreated"
                              :items="appointmentsTreated"
                              hide-default-footer
                              @page-count="pageCount = $event"
                              :single-expand="singleExpand"
                              :expanded.sync="expanded"
                              show-expand
                              sort-by="calories"
                              :footer-props="{
                                'items-per-page-text': 'produits par page(s)',
                              }"
                            >
                              <template v-slot:item.agency="{ item }">
                                <v-chip v-if="item.appointment_type === 'En agence'" small color="EoleYellow">
                                  Agence de {{ item.agency }}
                                </v-chip>
                              </template>

                              <template v-slot:item.identity="{ item }">
                                {{ item.lastname }} {{ item.firstname }}
                              </template>

                              <template v-slot:no-data>
                                <v-alert color="error" text class="ma-2">
                                  <div class="d-flex align-start">
                                    <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                                    <div class="ms-3">
                                      <p class="text-base font-weight-medium mb-1">
                                        Impossible d'accéder aux données. Merci de réessayer ultérieurement.
                                      </p>
                                    </div>
                                  </div>
                                </v-alert>
                                <v-btn color="primary" class="mb-2" @click="fetchInsurersByProduct()">Réessayer</v-btn>
                              </template>
                              <template v-slot:expanded-item="{ headers, item }">
                                <td class="pa-5" :colspan="headers.length">
                                  <v-row class="my-2">
                                    <v-col class="d-flex" cols="12">
                                      <v-card
                                        disabled
                                        outlined
                                        elevation="3"
                                        class="flex d-flex flex-column rounded-xl"
                                      >
                                        <v-card-text class="text-center">
                                          <i
                                            v-if="item.appointment_type === 'En agence'"
                                            class="fa-solid fa-calendar-days fa-3x EoleBlue--text text-center"
                                          ></i>
                                          <i v-else class="fa-solid fa-phone fa-3x EoleBlue--text text-center"></i>
                                        </v-card-text>
                                        <v-card-text class="text-center"> Une demande effectuée par : </v-card-text>
                                        <v-card-text class="text-center EoleBlue--text mt-n4">
                                          <p class="text-h6">
                                            {{ item.civility }}. {{ item.lastname }} {{ item.firstname }}
                                          </p>
                                        </v-card-text>
                                        <v-row v-if="item.date_start != null">
                                          <v-card-text class="text-center mt-n5">
                                            Demande de rendez-vous pour le :
                                          </v-card-text>
                                          <v-card-text class="text-center EoleYellow--text mt-n4">
                                            <p class="text-h6">{{ item.date }}</p>
                                            <p class="text-h6 mt-n6">{{ item.hourStart }} - {{ item.hourEnd }}</p>
                                          </v-card-text>
                                        </v-row>
                                        <v-row v-if="item.appointment_type === 'En agence'">
                                          <v-col cols="12" md="6">
                                            <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                                            <v-card-text class="text-center EoleBlue--text mt-n4">
                                              <p class="text-h6">{{ item.appointment_type }}</p>
                                            </v-card-text>
                                          </v-col>
                                          <v-col cols="12" md="6">
                                            <v-card-text class="text-center mt-n5"> A l'agence de : </v-card-text>
                                            <v-card-text class="text-center EoleBlue--text mt-n4">
                                              <p class="text-h6">{{ item.agency }}</p>
                                            </v-card-text>
                                          </v-col>
                                        </v-row>
                                        <v-row v-else>
                                          <v-col cols="12" md="6">
                                            <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                                            <v-card-text class="text-center EoleYellow--text mt-n4">
                                              <p class="text-h6">{{ item.appointment_type }}</p>
                                            </v-card-text>
                                          </v-col>
                                          <v-col cols="12" md="6" class="mt-3">
                                            <v-card-text class="text-center mt-n5"> </v-card-text>
                                            <v-card-text class="text-center EoleYellow--text mt-n4">
                                              <v-btn
                                                color="EoleBlue"
                                                class="white--text"
                                                :href="`tel:+${item.phone_number}`"
                                                >Appeler</v-btn
                                              >
                                            </v-card-text>
                                          </v-col>
                                        </v-row>
                                        <v-row class="pl-5 pr-5 pb-5 mt-n5 justify-center">
                                          <v-col
                                            cols="12"
                                            md="4"
                                            v-for="contract in item.contracts"
                                            v-bind:key="contract.name"
                                          >
                                            <v-card
                                              link
                                              @mouseover="contract.hovered = true"
                                              @mouseleave="contract.hovered = false"
                                              outlined
                                              width="125"
                                              height="100"
                                              elevation="3"
                                              class="align-center d-flex rounded-xl"
                                            >
                                              <v-card-text class="text-center pa-5">
                                                <i
                                                  :class="[
                                                    'mt-4 fa-solid ',
                                                    `fa-${contract.icon}`,
                                                    'fa-2x',
                                                    'EoleBlue--text',
                                                  ]"
                                                ></i>

                                                <p class="mt-2" style="font-size: 0.6rem">{{ contract.name }}</p>
                                              </v-card-text>
                                            </v-card>
                                          </v-col>
                                        </v-row>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                </td>
                              </template>
                            </v-data-table>
                            <div class="text-center pt-2">
                              <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </div>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-card>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item :value="'tab-5'" class="mb-5">
        <v-tabs
          right
          class="mx-2"
          v-model="tabCalendar"
          style="box-shadow: none !important"
          background-color="transparent"
        >
          <v-tab href="#tab-1-calendar">Hebdomadaire</v-tab>
          <v-tab href="#tab-2-calendar">Mensuel</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabCalendar">
          <v-tab-item :value="'tab-1-calendar'">
            <v-calendar
              :start="startDateCalendar"
              class="no-scrollbar"
              locale="fr"
              :weekdays="[1, 2, 3, 4, 5]"
              type="week"
              :events="events"
              :event-margin="2"
              :event-height="20"
              :first-interval="18"
              :interval-count="17"
              :interval-minutes="30"
              @click:event="showEvent"
            >
            </v-calendar>
            <v-dialog
              transition="slide-x-transition"
              max-width="900"
              v-model="dialogAppointment"
              close-on-content-click
            >
              <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                <v-card-text style="background-color: #f1f4f9">
                  <v-row>
                    <v-col cols="12" md="4" class="no-padding ml-n2">
                      <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                        <v-card-title>
                          <v-img
                            :src="require('@/assets/images/logos/anavel_logo.png')"
                            max-height="150px"
                            max-width="120px"
                            alt="logo"
                            contain
                          ></v-img>
                        </v-card-title>
                        <v-card-title class="text-center justify-center muller-capitalized"
                          >Mes Services Assurance</v-card-title
                        >
                        <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                        <v-card-text class="no-padding">
                          <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                          </v-img>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="8" class="no-padding d-flex">
                      <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                        <v-card-title class="text-h5">
                          <v-row>
                            <v-col cols="12" class="text-left">{{ selectedEvent.name }}</v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-text class="text-center mt-5">
                          <i
                            v-if="selectedEvent.appointment_type === 'En agence'"
                            class="fa-solid fa-calendar-days fa-3x EoleBlue--text text-center"
                          ></i>
                          <i v-else class="fa-solid fa-phone fa-3x EoleBlue--text text-center"></i>
                        </v-card-text>
                        <v-card-text class="text-center"> Une demande effectuée par : </v-card-text>
                        <v-card-text class="text-center EoleBlue--text mt-n4">
                          <p class="text-h6">
                            {{ selectedEvent.civility }} {{ selectedEvent.lastname }} {{ selectedEvent.firstname }}
                          </p>
                        </v-card-text>
                        <v-row v-if="selectedEvent.date_start != null">
                          <v-card-text class="text-center mt-n5"> Demande de rendez-vous pour le : </v-card-text>
                          <v-card-text class="text-center EoleYellow--text mt-n4">
                            <p class="text-h6">{{ selectedEvent.date }}</p>
                            <p class="text-h6 mt-n6">{{ selectedEvent.hourStart }} - {{ selectedEvent.hourEnd }}</p>
                          </v-card-text>
                        </v-row>
                        <v-row v-if="selectedEvent.appointment_type === 'En agence'">
                          <v-col cols="12" md="6">
                            <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                            <v-card-text class="text-center EoleBlue--text mt-n4">
                              <p class="text-h6">{{ selectedEvent.appointment_type }}</p>
                            </v-card-text>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-card-text class="text-center mt-n5"> A l'agence de : </v-card-text>
                            <v-card-text class="text-center EoleBlue--text mt-n4">
                              <p class="text-h6">{{ selectedEvent.agency }}</p>
                            </v-card-text>
                          </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col cols="12" md="6">
                            <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                            <v-card-text class="text-center EoleYellow--text mt-n4">
                              <p class="text-h6">{{ selectedEvent.appointment_type }}</p>
                            </v-card-text>
                          </v-col>
                          <v-col cols="12" md="6" class="mt-3">
                            <v-card-text class="text-center mt-n5"> </v-card-text>
                            <v-card-text class="text-center EoleYellow--text mt-n4">
                              <v-btn color="EoleBlue" class="white--text" :href="`tel:+${selectedEvent.phone_number}`"
                                >Appeler</v-btn
                              >
                            </v-card-text>
                          </v-col>
                        </v-row>
                        <v-row class="pl-5 pr-5 pb-5 mt-n5 justify-center">
                          <v-col
                            cols="12"
                            md="4"
                            v-for="contract in selectedEvent.contracts"
                            v-bind:key="contract.name"
                          >
                            <v-card
                              link
                              @mouseover="contract.hovered = true"
                              @mouseleave="contract.hovered = false"
                              outlined
                              width="125"
                              height="100"
                              elevation="3"
                              class="align-center d-flex rounded-xl"
                            >
                              <v-card-text class="text-center pa-5">
                                <i :class="['mt-4 fa-solid ', `fa-${contract.icon}`, 'fa-2x', 'EoleBlue--text']"></i>

                                <p class="mt-2" style="font-size: 0.6rem">{{ contract.name }}</p>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-spacer></v-spacer>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-row class="mt-2 text-center justify-center">
              <v-col
                v-for="collaborator in getUniqueCollaborator()"
                v-bind:key="collaborator.lastname_attributed"
                md="2"
              >
                <v-avatar size="16" :color="collaborator.color"></v-avatar>
                {{ collaborator.lastname_attributed }} {{ collaborator.firstname_attributed }}
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="'tab-2-calendar'">
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-sheet height="800px" color="transparent">
              <v-calendar
                ref="calendarMonth"
                :start="startDateCalendar"
                class="no-scrollbar"
                locale="fr"
                :weekdays="[1, 2, 3, 4, 5]"
                type="month"
                :events="events"
                @click:event="showEvent"
              >
              </v-calendar>
              <v-menu
                v-model="selectedOpen"
                class="no-scrollbar rounded-xl"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card class="no-scrollbar" min-width="350px" outlined elevation="3">
                  <v-toolbar color="EoleBlue" dark>
                    <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text class="text-center mt-5">
                    <i
                      v-if="selectedEvent.appointment_type === 'En agence'"
                      class="fa-solid fa-calendar-days fa-3x EoleBlue--text text-center"
                    ></i>
                    <i v-else class="fa-solid fa-phone fa-3x EoleBlue--text text-center"></i>
                  </v-card-text>
                  <v-card-text class="text-center"> Une demande effectuée par : </v-card-text>
                  <v-card-text class="text-center EoleBlue--text mt-n4">
                    <p class="text-h6">
                      {{ selectedEvent.civility }} {{ selectedEvent.lastname }} {{ selectedEvent.firstname }}
                    </p>
                  </v-card-text>
                  <v-row v-if="selectedEvent.date_start != null">
                    <v-card-text class="text-center mt-n5"> Demande de rendez-vous pour le : </v-card-text>
                    <v-card-text class="text-center EoleYellow--text mt-n4">
                      <p class="text-h6">{{ selectedEvent.date }}</p>
                      <p class="text-h6 mt-n6">{{ selectedEvent.hourStart }} - {{ selectedEvent.hourEnd }}</p>
                    </v-card-text>
                  </v-row>
                  <v-row v-if="selectedEvent.appointment_type === 'En agence'">
                    <v-col cols="12" md="6">
                      <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                      <v-card-text class="text-center EoleBlue--text mt-n4">
                        <p class="text-h6">{{ selectedEvent.appointment_type }}</p>
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card-text class="text-center mt-n5"> A l'agence de : </v-card-text>
                      <v-card-text class="text-center EoleBlue--text mt-n4">
                        <p class="text-h6">{{ selectedEvent.agency }}</p>
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" md="6">
                      <v-card-text class="text-center mt-n5"> Rendez-vous : </v-card-text>
                      <v-card-text class="text-center EoleYellow--text mt-n4">
                        <p class="text-h6">{{ selectedEvent.appointment_type }}</p>
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-3">
                      <v-card-text class="text-center mt-n5"> </v-card-text>
                      <v-card-text class="text-center EoleYellow--text mt-n4">
                        <v-btn color="EoleBlue" class="white--text" :href="`tel:+${selectedEvent.phone_number}`"
                          >Appeler</v-btn
                        >
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-row class="pl-5 pr-5 pb-5 mt-n5 justify-center">
                    <v-col cols="12" md="4" v-for="contract in selectedEvent.contracts" v-bind:key="contract.name">
                      <v-card
                        link
                        @mouseover="contract.hovered = true"
                        @mouseleave="contract.hovered = false"
                        outlined
                        width="125"
                        height="100"
                        elevation="3"
                        class="align-center d-flex rounded-xl"
                      >
                        <v-card-text class="text-center pa-5">
                          <i :class="['mt-4 fa-solid ', `fa-${contract.icon}`, 'fa-2x', 'EoleBlue--text']"></i>
                          <p class="mt-2" style="font-size: 0.6rem">{{ contract.name }}</p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </v-sheet>
            <v-row class="my-2 text-center justify-center">
              <v-col
                v-for="collaborator in getUniqueCollaborator()"
                v-bind:key="collaborator.lastname_attributed"
                md="2"
              >
                <v-avatar size="16" :color="collaborator.color"></v-avatar>
                {{ collaborator.lastname_attributed }} {{ collaborator.firstname_attributed }}
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>
<script>
import ical from "ical-generator"
import ics from "ics"
import Vue from "vue"
import VueConfetti from "vue-confetti"
import config from "@/views/config/config.js"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import moment from "moment"
moment.locale("fr")
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"
import { getCookie } from "@/utils/cookies"

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))
Vue.use(VueConfetti)
// import Snowf from "vue-snowf"

export default {
  components: {
    Loading,
    CustomeLoader,
  },
  data: () => ({
    dialogAppointment: false,
    transferInProgress: false,
    dialogTransferContact: false,
    searchCollaborator: "",
    transferInProgress: false,
    headersAppointmentsTreated: [
      {
        text: "type",
        value: "appointment_type",
      },
      {
        text: "Agence",
        value: "agency",
      },
      {
        text: "Identité",
        value: "identity",
      },
    ],
    isAdmin: null,
    eventData: {},
    waitTimeoutAppointments: false,
    waitTimeoutAppointmentsAttributed: false,
    waitTimeoutAppointmentsAttributedTreated: false,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    appointmentsAttributed: [],
    tabAppointment: null,
    tabCalendar: null,
    e1: 1,
    collaboratorName: null,
    clientTypologie: null,
    singleExpand: true,
    expanded: [],
    loading: false,
    page: 1,
    pageCount: 0,
    headersappointmentsTreated: [
      {
        text: "Agence",
        align: "start",
        value: "agency",
      },
      {
        text: "Date d'enregistrement",
        value: "datetime",
      },
      {
        text: "Identité client",
        value: "firstname",
      },
      {
        text: "Contact",
        value: "phoneNumber",
      },
    ],
    contactLate: 0,
    contactLateAll: 0,
    contactTotalCount: 0,
    tab: null,
    open: [],
    time: "",
    genderRules: [v => !!v || "Un genre est requis."],
    firstnameRules: [v => !!v || "Un prénom est requis."],
    lastnameRules: [v => !!v || "Un nom est requis."],
    agencyRules: [v => !!v || "Une agence est requise."],
    collaboratorRules: [v => !!v || "Un collaborateur à attribuer est requis."],
    reasonRules: [v => !!v || "Un motif est requis."],
    phoneRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "Un numéro de téléphone Français est composé de 10 chiffres",
    ],
    validityFormContact: false,
    validityFormContactPro: false,
    waitTimeout: false,
    waitTimeoutContact: false,
    collaboratorsAvailable: [],
    grade: null,
    dialog: false,
    selection: [],
    idSelect: -1,
    nb: 0,
    active: [],
    // users
    emailSent: {},
    statistics: [],
    contact: [],

    collaborators: [],
    collaboratorsTreated: [],
    appointmentsTreated: [],

    collaborator: [],

    idLast: -1,
    editedIndex: -1,
    editedItem: {
      id: null,
      name: "",
      gender: "",
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: null,
      idGRC: null,
      agence: null,
      reason: null,
    },
    appointments: [],
    startDateCalendar: "",
    //Pour mettre vide faire avec editedDefault
    contactMonthCalendar: [],
  }),
  computed: {
    items() {
      return [
        {
          id: 1,
          name: `Rendez-vous programmés`,
          children: this.collaborators,
        },
      ]
    },
    selected() {
      if (!this.active.length) return undefined

      const id = this.active[0]
      this.fetchUserContact(id)

      return this.collaborators.find(collaborators => collaborators.id === id)
    },
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search)
    const vtab = urlParams.get("vtab")
    this.tabAppointment = vtab

    console.log(this.tabAppointment)
    this.collaboratorName = localStorage.getItem("firstname")
    this.grade = localStorage.getItem("grade")
    this.grade === "administrateur" ? (this.isAdmin = true) : (this.isAdmin = false)
    let idCollaborator = this.$store.state.user.id

    // if (this.grade === "collaborateur") {
    //   this.fetchUserappointmentsTreated(localStorage.getItem("user_id"))
    // }
    this.setCalendarType()
    this.fetchUsers()
    this.fetchAppointmentsTreated(idCollaborator)
    this.fetchAppointments()
    this.fetchAttributedAppointments(idCollaborator)

    // this.affectEvents()
  },

  mounted() {},
  methods: {
    collaboratorFiltering(collaborators) {
      return collaborators.filter(collaborator => {
        const isUserValid = collaborator.completName.toLowerCase().includes(this.searchCollaborator.toLowerCase())

        return isUserValid
      })
    },
    getUniqueCollaborator() {
      /// return unique collaborator if have same name
      const uniqueCollaborator = [
        ...new Map(this.contactMonthCalendar.map(item => [item["lastname_attributed"], item])).values(),
      ]
      return uniqueCollaborator
    },
    setCalendarType() {
      const today = new Date()
      const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
      const nextWeekMonday = new Date(nextWeek)
      nextWeekMonday.setDate(nextWeek.getDate() - nextWeek.getDay() + 2)
      // Setting the range of dates to display
      const formattedDate = nextWeekMonday.toISOString().split("T")[0]
      this.startDateCalendar = formattedDate
    },
    next() {
      this.$refs.calendarMonth.next()
    },
    prev() {
      this.$refs.calendarMonth.prev()
    },

    icalGenerator2() {
      // Créez un nouvel objet iCalendar
      const event = ical({
        domain: "example.com",
        name: "My Test Feed",
        prodId: {
          company: "Anavel",
          product: "Mes Services Assurance",
          language: "FR",
        },
        timezone: "Europe/Berlin",
      })

      // Ajoutez un événement à l'iCalendar
      event.createEvent({
        start: new Date("2023-06-01T10:00:00"), // Date et heure de début de l'événement
        end: new Date("2023-06-01T11:00:00"), // Date et heure de fin de l'événement
        summary: "Nouveau rendez-vous", // Titre ou sujet de l'événement
        description: "Description de l'événement", // Description de l'événement
        organizer: {
          name: "John Doe", // Nom de l'organisateur
          email: "john@example.com", // Adresse e-mail de l'organisateur
        },
        attendees: [
          {
            name: "Jane Smith", // Nom du participant
            email: "jane@example.com", // Adresse e-mail du participant
          },
        ],
      })

      // Générez le contenu du fichier iCalendar
      const iCalContent = event.toString()

      const blob = new Blob([iCalContent], {
        type: "text/calendar;charset=utf-8",
      })
      const url = window.URL.createObjectURL(blob)

      emailjs
        .send("service_5p49t7p", "template_x0rummb", {
          to_name: "roro",
          to_email: "roman.frichot@anavel.bzh",
          to_copy_email: "roman.frichot.pro@gmail.com",
          company_name: "testcomp",
          products: ["foo", "bar"],
          reply_to: "contact@anavel.bzh",
          content: url,
          filename: "test.ics",
        })
        .then(response => {
          if (response.status === 200) {
            this.$toast.info(`Vous avez transféré cet appel.`, {
              position: "bottom-right",
              timeout: 5000,
            })
            window.location.reload()
          }
        })
    },
    icalGenerator(appointment) {
      // let ical =
      //   "BEGIN:VCALENDAR\nVERSION:2.0\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nPRODID:-//My calendar application//EN\n"
      // this.appointmentsTreated.forEach(appointment => {
      //   ical += "BEGIN:VEVENT\n"
      //   ical += `UID:${appointment.id}\n`
      //   ical += `DTSTAMP:${moment(appointment.datetime).format("YYYYMMDDTHHmmss")}\n`
      //   ical += `DTSTART:${moment(appointment.datetime).format("YYYYMMDDTHHmmss")}\n`
      //   ical +=
      //     `DTEND:${moment(appointment.datetime).add(1, "hour").format("YYYYMMDDTHHmmss")}\n`
      //   ical += `SUMMARY:${appointment.firstname} ${appointment.lastname}\n`
      //   ical += `DESCRIPTION:${appointment.reason}\n`
      //   ical += `LOCATION:${appointment.agency}\n`
      //   ical += "END:VEVENT\n"
      // })
      // ical += "END:VCALENDAR"
      // const blob = new Blob([ical], {
      //   type: "text/calendar"
      // })
      // const link = document.createElement("a")
      // link.href = window.URL.createObjectURL(blob)
      // link.download = "calendrier.ics"
      // link.click()
      let ical =
        "BEGIN:VCALENDAR\nVERSION:2.0\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nPRODID:-//My calendar application//EN\n"
      ical += "BEGIN:VEVENT\n"
      ical += `UID:${appointment.id}\n`
      ical += `DTSTAMP:${moment(appointment.date_start).format("YYYYMMDDTHHmmss")}\n`
      ical += `DTSTART:${moment(appointment.date_start).format("YYYYMMDDTHHmmss")}\n`
      ical += `DTEND:${moment(appointment.date_end).format("YYYYMMDDTHHmmss")}\n`
      ical += `SUMMARY:Rendez-vous ${appointment.firstname} ${appointment.lastname}\n`
      ical += `DESCRIPTION:Rendez-vous avec ${appointment.firstname} ${appointment.lastname}\n`
      ical += `LOCATION:${appointment.agency}\n`
      ical += "END:VEVENT\n"
      ical += "END:VCALENDAR"
      const blob = new Blob([ical], {
        type: "text/calendar",
      })

      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = "calendrier.ics"
      link.click()

      emailjs.send("service_5p49t7p", "template_x0rummb", {
        to_name: "roro",
        to_email: "roman.frichot@anavel.bzh",
        to_copy_email: "",
        company_name: "testcomp",
        products: JSON.stringify(appointment.products),
        reply_to: "contact@anavel.bzh",
      })
    },

    showEvent({ nativeEvent, event }) {
      this.dialogAppointment = true
      this.selectedEvent = event
      this.selectedElement = nativeEvent.target
    },
    async transferAppointment(appointment, newIdAccount) {
      this.transferInProgress = true
      const idAgency = this.$store.state.user.agency_id
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/appointments/${appointment.id}/affect`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            idAccount: newIdAccount,
          }),
        })

        if (response.ok) {
          this.$toast.info(`Vous avez transféré cet appel.`, {
            position: "bottom-right",
            timeout: 5000,
          })
          this.transferInProgress = false
          this.appointments = []
          this.appointmentsTreated = []
          this.appointmentsAttributed = []
          this.fetchAppointments()
          this.fetchAppointmentsTreated(this.$store.state.user.id)
          this.fetchAttributedAppointments(this.$store.state.user.id)
          this.$forceUpdate()
        } else {
          this.$toast.error(`Une erreur est survenue.\nImpossible de transfèrer l'appel`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de transfèrer l'appel`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },

    async transferAppointmentTest(appointment, newIdAccount, completName, idSelected) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(
          `${config.apiUri}/php/pages/customer_professionnal_employee_appointment.php?idAppointment=${appointment.id}&transferAppointment=true&newIdAccount=${newIdAccount}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        ).then(response => {
          if (response.status === 200) {
            if (appointment.appointment_type === "En agence") {
              try {
                emailjs.send("service_5p49t7p", "template_a04nij6", {
                  to_email: appointment.email,
                  collaborator_name: completName,
                  appointment_date: appointment.date,
                  appointment_hour_start: appointment.hourStart,
                  appointment_hour_end: appointment.hourEnd,
                  agency: appointment.agency,
                })
              } catch (error) {
                console.log(error)
              }
            } else {
              try {
                emailjs.send("service_5p49t7p", "template_bwk2zdd", {
                  to_email: appointment.email,
                  collaborator_name: completName,
                })
              } catch (error) {
                console.log(error)
              }
            }
          }
        })

        this.collaborators.find(collaborator => collaborator.id === newIdAccount).contactNumber++
        //   this.icalGenerator(appointment)

        // emailjs
        //   .send("service_5p49t7p", "template_3dsezmr", {
        //     to_name: name,
        //     to_email: email,
        //   })
        //   .then(response => {
        //     if (response.status === 200) {
        //       this.$toast.info(`Vous avez transféré cet appel.`, {
        //         position: "bottom-right",
        //         timeout: 5000,
        //       })
        //       window.location.reload()
        //     }
        //   })

        this.fetchUserContact(idSelected)
        this.$forceUpdate()
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de transfèrer l'appel`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },

    async fetchAttributedAppointments(idCollaborator) {
      let now = moment().format("YYYY-MM-DD")
      this.contactTotalCount = 0
      this.waitTimeoutContact = true
      this.appointmentsAttributed = []
      this.waitTimeoutAppointmentsAttributed = true

      let headers = new Headers()
      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        const response = await fetch(`${config.apiUri}/accounts/${idCollaborator}/appointments`, {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        const data = await response.json()

        data.forEach(el => {
          let anteriatory = false
          let maxDelay = null
          if (moment(now).isAfter(el.date_start)) {
            this.contactLate++
            anteriatory = true
          }

          if (el.appointment == 0) {
            maxDelay = moment(el.date_start).format("dddd Do MMMM YYYY")
          }

          this.appointmentsAttributed.push({
            id: el.id,
            commercial_name: el.commercial_name,
            date: moment(el.date_start).format("dddd Do MMMM YYYY"),
            hourStart: moment(el.date_start).format("HH:mm"),
            hourEnd: moment(el.date_end).format("HH:mm"),
            lastname: el.lastname,
            firstname: el.firstname,
            appointment_type: el.appointment_type,
            phone_number: el.phone_number,
            formated_phone_number: this.formatPhoneNumber(el.phone_number),
            agency: el.agency,
            contracts: JSON.parse(el.contracts),
            email: el.email,
            civility: el.civility,
            date_start: el.date_start,
            anteriatory: anteriatory,
            appointment: el.appointment,
            maxDelay: maxDelay,
          })

          // if date is late than now, increment contactTotalCount
          // console.log(moment(now).isAfter(el.date_start))
        })
        this.waitTimeoutAppointmentsAttributed = false
        this.waitTimeoutContact = false

        this.affectEvents()
      } catch (e) {
        console.log(e)
      }
    },

    async fetchAppointments() {
      let now = moment().format("YYYY-MM-DD")
      this.appointments = []
      this.waitTimeoutAppointments = true

      const idAgency = this.$store.state.user.agency_id
      try {
        let response = await fetch(`${config.apiUri}/agencies/${idAgency}/appointments`, {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        let data = await response.json()

        data.forEach(el => {
          let anteriatory = false
          let maxDelay = null
          if (moment(now).isAfter(el.date_start)) {
            this.contactLateAll++
            anteriatory = true
          }

          if (el.appointment == 0) {
            maxDelay = moment(el.date_start).format("dddd Do MMMM YYYY")
          }

          this.appointments.push({
            id: el.id,
            commercial_name: el.commercial_name,
            date: moment(el.date_start).format("dddd Do MMMM YYYY"),
            hourStart: moment(el.date_start).format("HH:mm"),
            hourEnd: moment(el.date_end).format("HH:mm"),
            lastname: el.lastname,
            firstname: el.firstname,
            email: el.email,
            appointment_type: el.appointment_type,
            phone_number: el.phone_number,
            formated_phone_number: this.formatPhoneNumber(el.phone_number),

            agency: el.agency,
            contracts: JSON.parse(el.contracts),
            date_start: el.date_start,
            date_end: el.date_end,
            civility: el.civility,
            anteriatory: anteriatory,
            appointment: el.appointment,
            maxDelay: maxDelay,
          })
        })
        this.waitTimeoutAppointments = false
      } catch (e) {
        this.$toast.error(`Impossible de récupérer les rendez-vous`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },
    onPaste() {
      return (this.editedItem.phoneNumber = this.editedItem.phoneNumber.replace(/ /g, ""))
    },
    test() {
      alert("GED POUR :")
    },

    formatPhoneNumber(number) {
      if (number.length == 10) {
        return number.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1.$2.$3.$4.$5")
      } else {
        return number.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5")
      }
    },
    func() {
      this.collaborators.forEach(el => {
        if (el.id === this.editedItem.id) {
          this.emailSent = {
            firstname: el.firstname,
            mail: el.email,
          }
        }
      })
    },
    sendmail() {
      try {
        emailjs.send("service_5p49t7p", "template_mv9abd5", {
          to_name: this.emailSent.firstname,
          //from_name: email,
          to_email: this.emailSent.mail,
          to_client: this.editedItem.lastName,
          receiver_name: this.emailSent.firstname,
          to_gender: this.editedItem.gender,
          sender_name: this.collaboratorName,
          to_client_mail: this.editedItem.email,
        })
      } catch (error) {
        console.log(error)
      }
    },
    sendMailClient() {
      if (this.clientTypologie === 1) {
        try {
          emailjs.send("service_5p49t7p", "template_lxbkpd9", {
            to_name: this.editedItem.lastName,
            receiver_name: this.emailSent.firstname,
            to_email: this.editedItem.email,
            to_gender: this.editedItem.gender,
            sender_name: this.collaboratorName,
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          emailjs.send("service_5p49t7p", "template_lxbkpd9", {
            to_name: this.editedItem.lastName,
            receiver_name: this.emailSent.firstname,
            to_email: this.editedItem.email,
            to_gender: this.editedItem.gender,
            sender_name: this.collaboratorName,
          })
        } catch (error) {
          console.log(error)
        }
      }
    },

    async archiveAppointment(idAppointment) {
      const idAgency = this.$store.state.user.agency_id

      this.$confetti.start()

      setTimeout(() => {
        this.$confetti.stop()
      }, 4000)

      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/appointments/${idAppointment}/archive`, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        if (response.ok) {
          this.appointments = []
          this.appointmentsTreated = []
          this.appointmentsAttributed = []
          this.fetchAppointments()
          this.fetchAppointmentsTreated(this.$store.state.user.id)
          this.fetchAttributedAppointments(this.$store.state.user.id).finally(() => {
            if (this.appointmentsAttributed.length === 0) {
              this.$toast.info(`Vous n'avez plus d'appel en attente.`, {
                position: "bottom-right",
                timeout: 5000,
              })
            } else if (this.appointmentsAttributed.length === 1) {
              this.$toast.info(`Vous avez traité cet appel, il ne vous reste plus qu'un appel en attente.`, {
                position: "bottom-right",
                timeout: 5000,
              })
            } else if (this.appointmentsAttributed.length > 1) {
              this.$toast.info(
                `Vous avez traité cet appel, il ne vous reste plus que ${this.appointmentsAttributed.length} appels en attente.`,
                {
                  position: "bottom-right",
                  timeout: 5000,
                },
              )
            }
          })
        }
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },
    validateFormContact(itemId) {
      if (this.clientTypologie === 1) {
        this.validityFormContact = true
        if (this.$refs.formContact.validate()) {
          this.save(itemId)
        }
      } else {
        this.validityFormContactPro = true
        if (this.$refs.formContactPro.validate()) {
          this.save(itemId)
        }
      }
    },

    copyClipboard(idElement) {
      let copyText = document.getElementById(idElement)

      try {
        copyText.select()
        navigator.clipboard.writeText(copyText.value)

        this.$toast.success(`Le texte a été copié dans votre presse-papier.`, {
          position: "bottom-right",
          timeout: 2000,
        })
      } catch (e) {
        this.$toast.error(`Impossible de copier le texte dans votre presse-papier.`, {
          position: "bottom-right",
          timeout: 2000,
        })
      }
    },

    async fetchAppointmentsTreated(idCollaborator) {
      this.appointmentsTreated = []
      this.waitTimeoutAppointmentsAttributedTreated = true

      await pause(1500)

      try {
        const responseContact = await fetch(`${config.apiUri}/accounts/${idCollaborator}/appointments/histories`, {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        const dataContact = await responseContact.json()

        dataContact.forEach(el => {
          let maxDelay = null

          if (el.appointment == 0) {
            maxDelay = moment(el.date_start).format("dddd Do MMMM YYYY")
          }

          this.appointmentsTreated.push({
            id: el.id,
            commercial_name: el.commercial_name,
            date: moment(el.date_start).format("dddd Do MMMM YYYY"),
            hourStart: moment(el.date_start).format("HH:mm"),
            hourEnd: moment(el.date_end).format("HH:mm"),
            lastname: el.lastname,
            firstname: el.firstname,
            email: el.email,
            appointment_type: el.appointment_type,
            phone_number: el.phone_number,
            formated_phone_number: this.formatPhoneNumber(el.phone_number),
            agency: el.agency,
            contracts: JSON.parse(el.contracts),
            date_start: el.date_start,
            date_end: el.date_end,
            civility: el.civility,
            maxDelay: maxDelay,
          })

          // this.fetchAttributorById(
          //   this.appointmentsTreated.map(ob => ob.idContact).indexOf(element.id),
          //   element.id_attributor,
          // )
        })

        this.collaboratorsTreated.forEach(collaborator => {
          if (collaborator.id === idCollaborator) {
            collaborator.contactNumber = dataContact.length
          }
        })
        this.waitTimeoutAppointmentsAttributedTreated = false
      } catch (e) {
        console.log(e)
      }
    },

    async fetchUsers() {
      this.collaborators = []
      this.collaborator = []
      this.collaboratorsAvailable = []
      this.waitTimeout = true

      await pause(1500)

      try {
        let headers = new Headers()
        let userId = localStorage.getItem("user_id")
        let idAgency = this.$store.state.user.agency_id

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/collaborators`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        await Promise.all(
          data.map(async (element, index) => {
            if (element.available == 1 && element.disabled == 0) {
              if (element.id != userId) {
                this.collaboratorsAvailable.push({
                  id: element.id,
                  lastname: element.lastname,
                  completName: element.lastname + " " + element.firstname,
                  firstname: element.firstname,
                  email: element.email,
                  contactNumber: 0,
                  contact_number: parseInt(element.number_contact),
                })
              }
            }

            if (element.disabled == 0) {
              this.collaborators.push({
                id: element.id,
                lastname: element.lastname,
                completName: element.lastname + " " + element.firstname,
                firstname: element.firstname,
                email: element.email,
                contactNumber: 0,
              })

              element.appointments.forEach(appointment => {
                this.events.push({
                  name: `RDV ${appointment.firstname} ${appointment.lastname} x ${element.lastname} ${element.firstname}`,
                  start: moment(appointment.date_start).format("YYYY-MM-DD HH:mm"),
                  end: moment(appointment.date_end).format("YYYY-MM-DD HH:mm"),
                  hourEnd: moment(appointment.date_end).format("HH:mm"),
                  hourStart: moment(appointment.date_start).format("HH:mm"),
                  appointment_type: appointment.appointment_type,
                  contracts: JSON.parse(appointment.contracts),
                  phone_number: appointment.phone_number,
                  agency: appointment.agency,
                  lastname: appointment.lastname,
                  firstname: appointment.firstname,
                  civility: appointment.civility,
                  date_start: appointment.date_start,
                  date_end: appointment.date_end,
                })
              })

              this.collaboratorsTreated.push({
                id: element.id,
                lastname: element.lastname,
                completName: element.lastname + " " + element.firstname,
                email: element.email,
                contactNumber: 0,
              })
            }
          }),
        )

        this.collaborators.forEach((collaborator, index) => {
          if (collaborator.id == userId && this.grade === "collaborateur") {
            this.collaborators.splice(index, 1)
          }
        })

        this.waitTimeout = false
      } catch (e) {
        console.log(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    generateRandomColor() {
      var color = "#"

      // Générer les composantes de couleur R, G et B de façon aléatoire
      var r = Math.floor(Math.random() * 256)
      var g = Math.floor(Math.random() * 256)
      var b = Math.floor(Math.random() * 256)

      // Convertir les valeurs en hexadécimal
      var hexR = r.toString(16).padStart(2, "0")
      var hexG = g.toString(16).padStart(2, "0")
      var hexB = b.toString(16).padStart(2, "0")

      // Vérifier si la couleur générée est blanche et la modifier si nécessaire
      if (hexR === "ff" && hexG === "ff" && hexB === "ff") {
        hexR = "00"
        hexG = "00"
        hexB = "00"
      }

      // Concaténer les composantes de couleur pour obtenir la couleur finale
      color += hexR + hexG + hexB

      return color
    },
    affectEvents() {
      this.contactMonthCalendar = this.contact
      this.contactMonthCalendar.forEach(el => {
        console.log("event", el)
        this.events.push({
          name: `RDV ${el.firstname} ${el.lastname} - ${el.lastname_attributed} ${el.firstname_attributed}`,
          start: moment(el.date_start).format("YYYY-MM-DD HH:mm"),
          end: moment(el.date_end).format("YYYY-MM-DD HH:mm"),
          hourEnd: el.hourEnd,
          hourStart: el.hourStart,
          appointment_type: el.appointment_type,
          contracts: el.contracts,
          phone_number: el.phone_number,
          agency: el.agency,
          lastname: el.lastname,
          firstname: el.firstname,
          color: el.color,
          civility: el.civility,
          date_start: el.date_start,
          date_end: el.date_end,
        })
      })
    },
    async fetchUserContact(idCollaborator) {
      this.contactTotalCount = 0
      this.waitTimeoutContact = true
      this.contact = []
      // generate random color hex except white

      let randomColor = this.generateRandomColor()

      let headers = new Headers()
      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")

        const responseContact = await fetch(`${config.apiUri}/accounts/${idCollaborator}/appointment`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const dataContact = await responseContact.json()

        dataContact.forEach(el => {
          this.contact.push({
            id: el.id,
            commercial_name: el.commercial_name,
            date: moment(el.date_start).format("dddd Do MMMM YYYY"),
            hourStart: moment(el.date_start).format("HH:mm"),
            hourEnd: moment(el.date_end).format("HH:mm"),
            lastname: el.lastname,
            firstname: el.firstname,
            email: el.email,
            appointment_type: el.appointment_type,
            contracts: el.contracts,
            phone_number: el.phone_number,
            agency: el.agency,
            contracts: JSON.parse(el.contracts),
            date_start: el.date_start,
            date_end: el.date_end,
            civility: el.civility,
            lastname_attributed: el.lastname_attributed,
            firstname_attributed: el.firstname_attributed,
            color: randomColor,
          })

          this.events.push({
            name: `RDV ${el.firstname} ${el.lastname} - ${el.lastname_attributed} ${el.firstname_attributed}`,
            start: moment(el.date_start).format("YYYY-MM-DD HH:mm"),
            end: moment(el.date_end).format("YYYY-MM-DD HH:mm"),
            hourEnd: el.hourEnd,
            hourStart: el.hourStart,
            appointment_type: el.appointment_type,
            contracts: el.contracts,
            phone_number: el.phone_number,
            agency: el.agency,
            lastname: el.lastname,
            firstname: el.firstname,
            color: randomColor,
            civility: el.civility,
            date_start: el.date_start,
            date_end: el.date_end,
          })

          console.log("events", this.events)

          // this.fetchAttributorById(this.contact.map(ob => ob.idContact).indexOf(element
          //   .id), element.id_attributor)
        })

        this.collaborators.forEach(collaborator => {
          if (collaborator.id === idCollaborator) {
            collaborator.contactNumber = dataContact.length
            this.contactTotalCount += dataContact.length
          }
        })

        this.waitTimeoutContact = false
      } catch (e) {
        console.log(e)
      }
    },
    async fetchAttributorById(index, idAttributor) {
      let headers = new Headers()
      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")

        const response = await fetch(`${config.apiUri}/accounts/${idAttributor}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(element => {
          this.contact[index].attributorName = element.lastname + " " + element.firstname
        })
      } catch (e) {
        console.log(e)
      }
    },
    async post(data) {
      try {
        let headers = new Headers()
        let datetime = require("moment")().format("YYYY-MM-DD HH:mm:ss")

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/php/pages/client_contact.php?idAccount=${data.id_account}&lastname=${data.lastname}&firstname=${data.firstname}&phoneNumber=${data.phone_number}&idGRC=${data.idGRC}&agency=${data.agency}&reason=${data.reason}&attributor=${data.id_attributor}&currentDatetime=${datetime}`,
          {
            mode: "no-cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          },
        )

        this.$toast.info(
          `Vous avez créer une demande de rappel pour ${
            this.emailSent.completName
          }.\nLe client ${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} doit être recontacté.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un nouveau compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    save(idUser) {
      let find = false
      this.idLast = idUser

      // this.users.push({id: this.idLast,
      //     name: this.editedItem.name,
      //     clientToRecontact : [{
      //       firstName: this.editedItem.firstName,
      //       lastName: this.editedItem.lastName,
      //       phoneNumber: this.editedItem.phoneNumber,
      //       idGRC: this.editedItem.idGRC,
      //       agence: this.editedItem.agence,
      //       reason: this.editedItem.reason
      //     }]
      // })
      this.post({
        id_account: this.idLast,
        firstname: this.editedItem.firstName,
        lastname: this.editedItem.lastName,
        phone_number: this.editedItem.phoneNumber,
        idGRC: this.editedItem.idGRC,
        agency: this.editedItem.agence,
        reason: this.editedItem.reason,
        id_attributor: localStorage.getItem("user_id"),
      })

      this.sendmail()
      this.sendMailClient()
      this.editedItem.name = null
      this.editedItem.firstName = null
      this.editedItem.lastName = null
      this.editedItem.phoneNumber = null
      this.editedItem.idGRC = null
      this.editedItem.agence = null
      this.editedItem.reason = null
      this.editedItem.email = null
      this.editedItem.id = null
      this.editedItem.gender = null

      this.nb = null

      this.close()
    },

    close() {
      this.dialog = false
      this.e1 = 1
    },
  },
}
</script>
<style lang="scss" scoped>
.v-menu__content {
  overflow: inherit !important;
}
.no-scrollbar ::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.menu-calendar {
  background-color: transparent !important;
  border-radius: 90px !important;
}

.no-padding {
  padding: 0 !important;
}

.v-treeview:hover {
  cursor: pointer;
}

@media (max-width: 600px) {
  .services {
    padding: 1.5rem !important;
  }

  .favorite-card {
    width: 100% !important;
  }
}

.greeting-card {
  position: relative;

  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }

    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
x
